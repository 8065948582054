<template>
		<div>
			<home-app-bar :drawer="drawer"
			@openDrawer="openCloseDrawer" />

			<home-view />

			<home-footer />
		</div>
</template>

<script>

  export default {
    name: 'webLayout',

    components: {
      HomeAppBar: () => import('@/layouts/global-components/app-bar'),
      HomeFooter: () => import('@/layouts/global-components/footer'),
      HomeSettings: () => import('@/layouts/website/settings'),
      HomeSystemBar: () => import('@/layouts/website/system-bar'),
      HomeView: () => import('@/layouts/website/view'),
		},
		
		data() {
			return {
				// Drawer
				drawer: false,
			}
		},
		methods: {
			openCloseDrawer(evt) {
				// console.log('openCloseDrawer evt', evt)
				this.drawer = evt
			}
		},
		mounted() {
			// console.log('process.env', process.env)
		}
  }
</script>
